import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { DEFAULT_IM_WERKS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getEquipmentList } from '../api/getEquipmentList';
import type { EquipmentResponse } from '../interfaces';

export const useEquipmentStore = defineStore('equipment', () => {
  const equipmentList = ref<EquipmentResponse | undefined>(undefined);
  const equipmentListFetchError = ref(false);
  const equipmentSearchValue = ref('');
  const lastSearchRoute = ref('');

  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchEquipmentListAsync = async (silent = false) => {
    const tryBlock = async () => {
      equipmentListFetchError.value = false;
      const response = await getEquipmentList(
        profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS,
      );
      if (handleResponse(response)) {
        equipmentList.value = response.data as EquipmentResponse;
        // Если статуса нет, то не используем данную ЕО
        equipmentList.value.EX_EQUIP_LIST = equipmentList.value.EX_EQUIP_LIST.filter(item => item.STTXU);
      } else {
        equipmentListFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchEquipmentListAsync', noGlobalLoader: silent });
  };

  const addLocalMeasureItems = (measureItems: EquipmentResponse['EX_MEASURE']) => {
    if (equipmentList.value?.EX_MEASURE) {
      equipmentList.value.EX_MEASURE.push(...measureItems);
    }
  };

  return {
    equipmentList,
    equipmentListFetchError,
    fetchEquipmentListAsync,
    addLocalMeasureItems,
    equipmentSearchValue,
    lastSearchRoute,
  };
});
