<script setup lang="ts">
import { computed } from 'vue';
import { ChevronRightFilledIcon } from '@/shared/assets/svg';
import {
  NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_LINES_CREATE_DEFECT,
} from '@/shared/lib/constants';
import type { FucLockByParentNode } from '../../interfaces';
import type { EquipmentNodesByParent } from '../../interfaces/Equipment';
import type { FuncLock } from '../../interfaces/FuncLocks';
import styles from './EquipmentLinesList.module.scss';

interface Props {
  areaId: string; //Линиями являются дочерние оборудования участка и дочерние оборудования всех линей ниже по цепочке
  funcLocByParentNode: FucLockByParentNode;
  equipmentNodesByParentLines: EquipmentNodesByParent;
}

const { areaId, funcLocByParentNode, equipmentNodesByParentLines } = defineProps<Props>();

const flatLines = computed(() => {
  console.log("EquipmentList: funcLocByParentNode:", funcLocByParentNode);
  console.log('EquipmentList equipmentNodesByParentLines', equipmentNodesByParentLines);

  const getResult = (startId: string): FuncLock[] => {
    const finalResult: FuncLock[] = [];

    const processNodes = (itemId: string) => {
      // Данная проверка нужна чтобы выяснить, есть ли у участка технические места, если их нет, то техническим местом для ЕО будет участок
      if (!funcLocByParentNode[itemId]) return;

      funcLocByParentNode[itemId].forEach((item) => {
        const children = equipmentNodesByParentLines[item.TPLNR] || [];
        children.sort((a, b) => (a.EQUNR < b.EQUNR ? -1 : 1));

        finalResult.push({
          ...item,
          equipmentNodes: children,
        });

        item.TPLNR !== areaId && processNodes(item.TPLNR);
      });
    };

    processNodes(startId);
    return finalResult;
  };

  if (funcLocByParentNode[areaId]) {
    return getResult(areaId);
  }

  return [];
});

 console.log("EquipmentList: flatLines:", flatLines);
</script>

<template>
  <ul :class="styles.list">
    <li :class="styles.item" v-for="(item, index) in flatLines" :key="index">
      <div :class="styles.line">{{ item.PLTXT }}</div>
      <router-link
        v-for="equipmentNode in item.equipmentNodes"
        :key="index"
        :class="styles.link"
        :to="{
          name:
            $route.name === NAV_EQUIPMENT_LINES_CREATE_DEFECT
              ? NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL
              : NAV_EQUIPMENT_DETAIL,
          params: { equipmentId: equipmentNode.EQUNR },
        }"
      >
        <a-typography-text :class="styles.text">{{ equipmentNode.EQKTX }}</a-typography-text>
        <ChevronRightFilledIcon :class="styles.arrow" />
      </router-link>
    </li>
  </ul>
</template>
