<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DefectCreateForm } from '@/features/DefectManagement';
import { useGetSymptoms } from '@/entities/Defects';
import { useOperationInfo } from '@/entities/Orders';
import { useGetPriorityList } from '@/entities/Priorities';
import { NAV_OPERATIONS_SLIDER } from '@/shared/lib';
import { HandleRequest, PageHeading } from '@/shared/ui';
import styles from './DefectCreate.module.scss';
import type { Equipment } from '@/entities/Equipments/interfaces/Equipment';

const route = useRoute();
const router = useRouter();
const { orderId, operationId } = route.params;
const {
  priorityList,
  priorityListFetchError,
  reloadFetchRequest: priorityListReload,
} = useGetPriorityList();
const { operation } = useOperationInfo(orderId as string, operationId as string);

const { symptoms, symptomsFetchError, reloadFetchRequest: symptomsReload } = useGetSymptoms();

const node = ref<Equipment | undefined>(
  undefined,
);

watchEffect(() => {
  if (operation.value) {
    node.value = {
      EQUNR: operation.value?.EQUNR || '',
      EQKTX: operation.value?.EQKTX || '',
      RBNR: operation.value?.RBNR || '',
      TPLNR: operation.value?.TPLNR || '',
    } as Equipment;
  }
});

const onReload = () => {
  if (priorityListFetchError) {
    priorityListReload();
  }

  if (symptomsFetchError) {
    symptomsReload();
  }
};

const handleBack = () => {
  router.replace({ name: NAV_OPERATIONS_SLIDER, params: { orderId, operationId } });
};
</script>

<template>
  <PageHeading text="Создать дефект" :goBack="true" :onHandleBack="handleBack" />
  <div :class="styles.page">
    <HandleRequest
      :noData="!node || !priorityList?.EX_PRIORITY.length || !symptoms"
      :errorRequest="priorityListFetchError || symptomsFetchError"
      :onRepeat="onReload"
    />
    <template v-if="node && priorityList?.EX_PRIORITY.length && symptoms">
      <DefectCreateForm
        :TPLNR="operation?.TPLNR"
        :nodes="[node]"
        :priorityList="priorityList.EX_PRIORITY"
        :symptoms="symptoms.EX_TORO_SYMPTOMS"
    /></template>
  </div>
</template>
