import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { DEFAULT_IM_WERKS, enterprises } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getSectors } from '@/shared/api/getSectors';
import { getAlternativeSectors } from '@/shared/api';
import type { SectorsResponse, Sector } from '../interfaces';
import type { AlternativeSector  } from '@/shared/interfaces';
import type { AxiosResponse } from 'axios';

export const useSectorsStore = defineStore('sectors', () => {
  const sectors = ref<SectorsResponse | undefined>(undefined);
  const sectorsFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchSectorsAsync = async (silent = false) => {
    const tryBlock = async () => {
      sectorsFetchError.value = false;

      const enterpriseSapCode = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;

      const response: AxiosResponse = enterpriseSapCode === enterprises.EEK ?
        await getAlternativeSectors(enterpriseSapCode) :
        await getSectors(enterpriseSapCode);

      if (handleResponse(response)) {
        if (enterpriseSapCode === enterprises.EEK ) {
          const techplace: Sector[] = response.data?.EX_TECHPLACE.map((item: AlternativeSector) => ({
            PLTXT: item.PLOT_NAME,
            TPLNR: item.PLOT_CODE,
            WERKS: item.WERKS,
            WERKS_NAME: item.WERKS_NAME,
            WORK_CODE: item.WORK_CODE,
            WORK_NAME: item.WORK_NAME 
          }));

          const formattedSectors: SectorsResponse = {
            EX_TECHPLACE: techplace,
          }

          sectors.value = formattedSectors;
        } else {
          sectors.value = response.data as SectorsResponse;
        }
      } else {
        sectorsFetchError.value = true;
      }

      console.log('sectors.value useSectorsStore', sectors.value);
    };
    await handleLoading({ tryBlock, funcName: 'fetchSectorsAsync', noGlobalLoader: silent });
  };

  return {
    sectors,
    sectorsFetchError,
    fetchSectorsAsync,
  };
});
