<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { PageHeading, PageTabs } from '@/shared/ui';
import { tabs } from '../lib/tabs';
import styles from './Defects.module.scss';
import { useGetSectors } from '@/entities/Sectors/hooks/useGetSectors';

const { sectorId } = useRoute().params;

const { sectors } = useGetSectors();

const sectorName = computed(() => {
  const sector = sectors.value?.EX_TECHPLACE.find(item => item.TPLNR === sectorId);
  return sector ? sector.PLTXT : '';
});
</script>

<template>
  <div :class="styles.area">
    <PageHeading :text="sectorName" :goBack="true" />
    <PageTabs :tabs="tabs" saveTabs :additionalHash="sectorId as string" />
  </div>
</template>
