import { ref, watchEffect } from 'vue';
import type { FucLockByParentNode } from '../interfaces';
import { useGetFuncLocList } from './useGetFuncLocList';
import { storeToRefs } from 'pinia';
import { useUserProfileStore } from '@/shared/model';
import { useSectorsStore } from '@/shared/model/useSectorsStore';
import { DEFAULT_IM_WERKS, enterprises } from '@/shared/lib';

export const useGetFuncLocksWerksAndAreas = () => {
  const { funcLockList, funcLockFetchError, reloadFetchRequest } = useGetFuncLocList();
  const { profile } = storeToRefs(useUserProfileStore());
  const { sectors } = storeToRefs(useSectorsStore());

  const funcLocByParentNode = ref<FucLockByParentNode | undefined>(undefined);

  watchEffect(() => {
    const enterpriseSapCode = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;
    if (funcLockList.value) {
      if (enterpriseSapCode === enterprises.EEK) {
        function initializeFuncLocByParentNode() {
          if (!funcLocByParentNode.value) {
            funcLocByParentNode.value = {};
          }
        }

        function ensureArrayExists(key: string) {
          if (!funcLocByParentNode.value![key]) {
            funcLocByParentNode.value![key] = [];
          }
        }

        function addItemToFuncLoc(werksKey: string, item: any, key: number) {
          if (
            !funcLocByParentNode.value![werksKey].some(
              (el) => el.TPLNR === item.WORK_CODE
            )
          ) {
            funcLocByParentNode.value![werksKey].push({
              TPLNR: item.WORK_CODE!,
              WERKS: item.WERKS!,
              PLTXT: item.WORK_NAME!,
              TPLMA: item.WERKS!,
              hhive_id: key + Math.random(),
            });
          }
        }

        function addWorkshopItem(workCodeKey: string, item: any, key: number) {
          if (
            !funcLocByParentNode.value![workCodeKey].some(
              (el) => el.TPLNR === item.TPLNR
            )
          ) {
            funcLocByParentNode.value![workCodeKey].push({
              TPLNR: item.TPLNR,
              WERKS: item.WERKS!,
              PLTXT: item.PLTXT,
              TPLMA: item.WORK_CODE!,
              hhive_id: key + 10,
            });
          }
        }

        sectors.value?.EX_TECHPLACE.forEach((item, key) => {
          initializeFuncLocByParentNode();

          // Убедиться, что массивы существуют
          ensureArrayExists(item.WERKS!);
          ensureArrayExists(item.WORK_CODE!);

          // Добавление объектов
          addItemToFuncLoc(item.WERKS!, item, key);
          addWorkshopItem(item.WORK_CODE!, item, key);
        });

        function isValidFormat(value: string): boolean {
          const pattern = /^\d{4}-\d{3}-\d{3}$/;
          return pattern.test(value);
        }

        funcLockList.value?.EX_FUNC_LOC.forEach((equipItem) => {
          initializeFuncLocByParentNode();

          Object.keys(funcLocByParentNode.value!).forEach((key) => {
            funcLocByParentNode.value![key].forEach((value) => {
              const isMatch =
                value.TPLNR === equipItem.TPLNR ||
                value.TPLMA === equipItem.TPLNR ||
                value.WERKS === equipItem.TPLNR;

              if (!isMatch && value.TPLNR === equipItem.TPLNR.slice(0, 12)) {
                ensureArrayExists(equipItem.TPLMA);
                funcLocByParentNode.value![equipItem.TPLMA].push(equipItem);
              } else if (value.TPLNR === equipItem.TPLNR && isValidFormat(value.TPLNR) && !funcLockList.value?.EX_FUNC_LOC.some(tm => tm.TPLMA === value.TPLNR)) {
                ensureArrayExists(equipItem.TPLNR);
                funcLocByParentNode.value![value.TPLNR].push({
                  TPLNR: value.TPLNR,
                  WERKS: value.WERKS,
                  PLTXT: value.PLTXT,
                  TPLMA: value.TPLNR,
                  hhive_id: value.hhive_id,
                })
              }
            });
          });
        });
      } else {
        funcLockList.value.EX_FUNC_LOC?.forEach(item => {
          if (!funcLocByParentNode.value) {
            funcLocByParentNode.value = {};
          }
          if (!funcLocByParentNode.value[item.TPLMA]) {
            funcLocByParentNode.value[item.TPLMA] = [];
          }
          funcLocByParentNode.value[item.TPLMA].push(item);
        });
      }
    }

    console.log('sectors in funcLocByParentNode', funcLocByParentNode);
    console.log('sectors in funcLockList', funcLockList);
  });
  return {
    funcLocByParentNode,
    funcLockFetchError,
    reloadFetchRequest,
  };
};
